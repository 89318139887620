import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { RouterActions, RouterSelectors } from '.';
import { Store } from '@ngrx/store';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private notification: NotificationService,
  ) {}

  navigateByUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.navigateByUrlAction),
        tap(action => this.router.navigateByUrl(action.url, action.navigationExtras)),
      ),
    { dispatch: false },
  );

  routeChanged$ = createEffect(
    () => this.store.select(RouterSelectors.selectUrl).pipe(tap(_ => this.notification.dismiss())),
    { dispatch: false },
  );
}
