import { Injectable, inject } from '@angular/core';
import { Firestore, collection, orderBy, query, where } from '@angular/fire/firestore';
import { FirestoreHelperService, Pagination } from 'src/app/shared/services/firestore-helper.service';
import { RiderAggregate, RiderInvitationAggregate } from 'src/app/shared/models/firestore.model';
import { QueryCompositeFilterConstraint, QueryNonFilterConstraint, and, or } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class UsersFirestoreService {
  private firestore = inject(Firestore);
  private firestoreHelper = inject(FirestoreHelperService);

  listRiderInvitationAggregates(
    businessId: string,
    filter: { email: string | null },
    pagination: Pagination
  ) {

    const collectionPath = `role/external_account_manager/business_aggregate/${businessId}/invitation_aggregate`;
    const colRef = collection(this.firestore, collectionPath);

    const constraints: QueryCompositeFilterConstraint = and(
      or(
        and(where('invitation.deleted', '==', false), where('business_profile.deleted', '==', false)),
        and(where('invitation.deleted', '==', false), where('invitation.accepted', '==', false)),
      ),
      and(where('invitation.email', '>=', filter.email), where('invitation.email', '<=', filter.email + '\uf8ff'))
    );

    const nonQueryConstraints: QueryNonFilterConstraint[] = [orderBy('invitation.accepted', 'desc')];
    nonQueryConstraints.push(orderBy('invitation.email', 'asc'));
    const q = query(colRef, constraints, ...nonQueryConstraints);

    return this.firestoreHelper.getDocsPageable<RiderInvitationAggregate>(q, collectionPath, pagination);
  }

  watchRiderInvitationAggregate(businessId: string, invitationId: string) {
    return this.firestoreHelper.docData<RiderInvitationAggregate>(`role/external_account_manager/business_aggregate/${businessId}/invitation_aggregate/${invitationId}`);
  }

  listRiders(riderIds: string[]) {
    const collectionPath = `role/external_account_manager/rider_aggregate`;

    return this.firestoreHelper.getDocumentsByIds<RiderAggregate>(collectionPath, riderIds);    
  }
}
