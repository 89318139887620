import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromBookRide from './book-ride.reducer';
import { EffectsModule } from '@ngrx/effects';
import { FirestoreService } from 'src/app/shared/services/firestore-service';
import { BookRideEffects } from './book-ride.effects';


@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(fromBookRide.bookRideFeatureKey, fromBookRide.reducer),
  EffectsModule.forFeature([BookRideEffects])],
  providers: [
    FirestoreService
  ]
})
export class BookRideStoreModule { }
