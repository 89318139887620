import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, of, switchMap, take } from 'rxjs';
import { AuthSelectors } from 'src/app/auth/store';
import { RouterActions } from 'src/app/shared/store/modules/router';
import { selectExternalAccountManagerAggregate } from '../../auth/store/auth.selectors';

export const authGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const navigateTo = (currentRouteUrl: string, url: string) => {
    if (currentRouteUrl === url) {
      return true;
    }
    store.dispatch(RouterActions.navigateByUrlAction({ url: url }));
    return false;
  };
  return store.select(AuthSelectors.selectUid).pipe(
    filter(uid => uid !== undefined), // wait for auth state user to be loaded
    take(1),
    switchMap(uid => {
      const currentRouteUrl = state.url;
      if (!uid) {
        if (currentRouteUrl.includes('auth')) {
          return of(true);
        } else {
          return of(navigateTo(currentRouteUrl, '/auth/login'));
        }
      }
      return store.select(AuthSelectors.selectUserRoles).pipe(
        filter(roles => roles !== undefined),
        take(1),
        switchMap(roles => {
          if (!roles.includes('b2b-web.external_account_manager') && roles.includes('b2b-web.agent')) {
            if (currentRouteUrl.endsWith('home')) {
              return of(navigateTo(currentRouteUrl, '/home/welcome'));
            } else {
              return of(true);
            }
          } else {
            return store.select(selectExternalAccountManagerAggregate).pipe(
              filter(aggregate => !!aggregate),
              map(aggregate => {
                // user logged, but hasn't finished registration, navigate to sign up
                if (!aggregate?.external_account_manager?.business_id) {
                  return navigateTo(currentRouteUrl, '/auth/sign-up');
                } else {
                  if (currentRouteUrl.endsWith('home')) {
                    store.dispatch(RouterActions.navigateByUrlAction({ url: '/home/dashboard' }));
                    return false;
                  } else {
                    return true;
                  }
                }
              }),
            );
          }
        }),
      );
    }),
  );
};
