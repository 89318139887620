import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromRequestRide from './request-ride.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RequestRideEffects } from './request-ride.effects';


@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromRequestRide.requestRideFeatureKey, fromRequestRide.reducer),
    EffectsModule.forFeature([RequestRideEffects])
  ]
})
export class RequestRideStoreModule { }
