import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromRides from './rides.reducer';
import { EffectsModule } from '@ngrx/effects';
import { RidesEffects } from './rides.effects';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromRides.ridesFeatureKey, fromRides.reducer),
    EffectsModule.forFeature([RidesEffects]),
    MatDialogModule,
  ],
})
export class RidesStoreModule {}
