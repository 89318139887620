import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatWith, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthActions, AuthSelectors } from 'src/app/auth/store';
import { BusinessAggregate } from 'src/app/shared/models/firestore.model';
import { FirestoreService } from 'src/app/shared/services/firestore-service';
import * as HomeActions from './home.actions';
import { whenPageVisible } from '../../shared/utils/when-page-visible';

@Injectable()
export class HomeEffects {
  constructor(
    private actions$: Actions,
    private firestore: FirestoreService,
    private store: Store,
  ) {}

  externalAccountManagerBusinessIdChanged$ = createEffect(() =>
    this.store.select(AuthSelectors.selectBusinessId).pipe(
      filter(businessId => !!businessId),
      switchMap(businessId =>
        this.firestore
          .watchBusinessAggregate(businessId)
          .pipe(
            whenPageVisible(),
            takeUntil(this.actions$.pipe(ofType(AuthActions.logoutClicked))),
            takeUntil(this.store.select(AuthSelectors.selectBusinessId).pipe(filter(businessId => !businessId))),
            concatWith(of(<BusinessAggregate>null)),
          ),
      ),
      switchMap(aggregate => [HomeActions.businessAggregateChanged({ aggregate })]),
    ),
  );
}
