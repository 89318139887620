import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DashboardStoreModule } from '../dashboard/store/dashboard-store.module';
import { RequestRideStoreModule } from '../request-ride/store/request-ride-store.module';
import { HomeEffects } from './home.effects';
import * as fromHome from './home.reducer';
import { UsersStoreModule } from '../users/store/users-store.module';
import { RidesStoreModule } from '../rides/store/rides-store.module';
import { BookRideStoreModule } from '../book-ride/store/book-ride-store.module';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromHome.homeFeatureKey, fromHome.reducer),
    EffectsModule.forFeature([HomeEffects]),
    RequestRideStoreModule,
    DashboardStoreModule,
    UsersStoreModule,
    RidesStoreModule,
    BookRideStoreModule,
  ],
})
export class HomeStoreModule {}
