import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { B2BWebApiService } from 'src/app/shared/services/b2b-web-api.service';
import { FirestoreService } from 'src/app/shared/services/firestore-service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { combineLatest, interval, map, merge, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { BookRideSelectors, BookRideActions } from '../store';
import { catchError, filter, take, tap, takeUntil, startWith, debounceTime } from 'rxjs/operators';
import { LatLng } from '../../../shared/models/latlng.model';
import { LocationVM } from '../../../shared/models/location.vm';
import {
  mapAutoCompleteResponseToSuggestionsVM,
  mapPaymentMethodTypeVMToPaymentMethodType,
  mapPlaceDetailsResponseToLocationVM,
} from './mappings';
import { RidesFirestoreService } from '../../rides/services/rides-firestore.service';
import { whenPageVisible } from '../../../shared/utils/when-page-visible';
import { AuthSelectors } from 'src/app/auth/store';
import { uuid } from 'src/app/shared/utils/uuid';
import { getRideStatus } from '../../rides/store/mappings';
import { RideStatus } from 'src/app/shared/models/rides.vm';
import { StringUtils } from '../../../shared/utils/string-utils';
import { BookingAggregate } from 'src/app/shared/models/firestore.model';
import { LookupNearbyDriversRequest } from 'src/app/shared/models/b2b-web-api.model';
import { PaymentMethodTypeVM } from 'src/app/shared/models/business.vm';

@Injectable()
export class BookRideEffects {
  constructor(
    private actions$: Actions,
    private api: B2BWebApiService,
    private store: Store,
    private notification: NotificationService,
    private firestoreService: FirestoreService,
    private ridesFirestoreService: RidesFirestoreService,
  ) {}

  createFromInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.createFromInitialized),
      switchMap(() =>
        merge(
          this.selectTemplate(),
          this.watchPlatformSettings(),
          this.watchAreas(),
          this.selectPaymentMethod(),
          this.watchBusinessNote(),
          this.loadAllPackages(),
          this.loadAllVehicleTypes(),
        ),
      ),
    ),
  );

  resetFormFieldButtonClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.resetFormFieldButtonClicked),
      switchMap(() => merge(this.selectTemplate(), this.selectPaymentMethod(), this.selectPackage())),
    ),
  );

  private selectPaymentMethod() {
    return this.store.select(BookRideSelectors.selectBusinessPaymentMethodTypes).pipe(
      filter(method => !!method),
      take(1),
      map(method =>
        BookRideActions.paymentMethodTypeChanged({
          paymentMethodType: method.find(m => m === PaymentMethodTypeVM.IN_PERSON) || method[0],
        }),
      ),
    );
  }

  private selectTemplate() {
    return this.store.select(BookRideSelectors.selectAgentTemplates).pipe(
      filter(templates => templates.length > 0),
      take(1),
      map(templates => {
        const localStorageTemplateId = JSON.parse(localStorage.getItem('selectedTemplateId'));
        const selectedTemplate =
          localStorageTemplateId && templates.find(t => t.pickupLocation.formattedAddress === localStorageTemplateId);
        return BookRideActions.templateSelected({
          templateId: selectedTemplate?.templateId || templates[0].pickupLocation.formattedAddress,
        });
      }),
    );
  }

  private watchPlatformSettings() {
    return this.firestoreService.watchPlatformSettings().pipe(
      whenPageVisible(),
      takeUntil(this.actions$.pipe(ofType(BookRideActions.createFormDestroyed))),
      map(settings => BookRideActions.platformSettingsLoaded({ settings })),
      catchError(error => {
        this.notification.error(error);
        return [];
      }),
    );
  }

  private watchBusinessNote() {
    return this.store.select(BookRideSelectors.selectBusinessId).pipe(
      filter(id => !!id),
      switchMap(businessId =>
        this.firestoreService.watchBusinessNote(businessId).pipe(
          whenPageVisible(),
          takeUntil(this.actions$.pipe(ofType(BookRideActions.createFormDestroyed))),
          map(note => BookRideActions.businessNoteLoaded({ note })),
        ),
      ),
      catchError(error => {
        this.notification.error(error);
        return [];
      }),
    );
  }

  private watchAreas() {
    return this.firestoreService.watchAreas().pipe(
      whenPageVisible(),
      takeUntil(this.actions$.pipe(ofType(BookRideActions.createFormDestroyed))),
      map(areas => BookRideActions.areasLoaded({ areas })),
      catchError(error => {
        this.notification.error(error);
        return [];
      }),
    );
  }

  templateIdSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.templateSelected),
      filter(action => !!action.templateId),
      withLatestFrom(this.store.select(BookRideSelectors.selectAgentTemplates)),
      map(([action, templates]) => {
        const template = templates.find(t => t.pickupLocation.formattedAddress === action.templateId);
        localStorage.setItem('selectedTemplateId', JSON.stringify(template.templateId));
        return template ? BookRideActions.pickupLocationChanged({ pickupLocation: template.pickupLocation }) : null;
      }),
    ),
  );

  pickupLocationTyped$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.pickupLocationTyped),
      tap(() => this.notification.dismiss()),
      withLatestFrom(this.store.select(BookRideSelectors.selectMapCenter)),
      switchMap(([action, mapCenter]) => this.autocomplete(action.query, action.sessionToken, mapCenter)),
      map(suggestions => BookRideActions.pickupLocationSuggestionsLoaded({ suggestions })),
    ),
  );

  pickupSuggestionSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.pickupSuggestionSelected),
      switchMap(action => {
        if (action.pickupLocation?.source === 'SOURCE_TYPE_GOOGLE') {
          return this.api
            .placeDetails({
              placeId: action.pickupLocation.placeId,
              sessionToken: action.sessionToken,
              language: navigator.language,
            })
            .pipe(map(response => mapPlaceDetailsResponseToLocationVM(response, action.pickupLocation)));
        } else {
          return of(action.pickupLocation as LocationVM);
        }
      }),
      map(location => BookRideActions.pickupLocationChanged({ pickupLocation: location })),
    ),
  );

  dropoffLocationTyped$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.dropoffLocationTyped),
      filter(action => action.query !== ''),
      withLatestFrom(this.store.select(BookRideSelectors.selectPickupLocation)),
      switchMap(([action, pickupLocation]) => this.autocomplete(action.query, action.sessionToken, pickupLocation)),
      map(suggestions => BookRideActions.dropoffLocationSuggestionsLoaded({ suggestions })),
    ),
  );

  dropoffSuggestionSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.dropoffSuggestionSelected),
      switchMap(action => {
        if (action.dropoffLocation?.source === 'SOURCE_TYPE_GOOGLE') {
          return this.api
            .placeDetails({
              placeId: action.dropoffLocation.placeId,
              sessionToken: action.sessionToken,
              language: navigator.language,
            })
            .pipe(map(response => mapPlaceDetailsResponseToLocationVM(response, action.dropoffLocation)));
        } else {
          return of(action.dropoffLocation as LocationVM);
        }
      }),
      map(location => BookRideActions.dropoffLocationChanged({ dropoffLocation: location })),
    ),
  );

  private autocomplete(query: string, sessionToken: string, around: LatLng = { lat: 55.6761, lng: 12.5683 }) {
    return this.api
      .placeAutocomplete({
        input: query,
        location: around,
        language: navigator.language,
        radius: 50000,
        sessionToken: sessionToken,
      })
      .pipe(map(response => mapAutoCompleteResponseToSuggestionsVM(response)));
  }

  pickupLocationChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.pickupLocationChanged),
      withLatestFrom(this.store.select(BookRideSelectors.selectAreaId)),
      switchMap(([action, areaId]) =>
        this.api.areaLookup({ lat: action.pickupLocation.lat, lng: action.pickupLocation.lng }).pipe(
          filter(response => StringUtils.areStringsEqual(response?.area?.id, areaId) === false),
          tap(response =>
            StringUtils.isNullOrEmptyOrWhitespace(response?.area?.id)
              ? this.notification.error(
                  'Viggo is not operating in this area. If you think we should, please contact support.',
                )
              : null,
          ),
          map(response => BookRideActions.areaIdChanged({ areaId: response?.area?.id })),
        ),
      ),
    ),
  );

  areaIdChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.areaIdChanged),
      filter(action => !!action.areaId),
      withLatestFrom(this.store.select(BookRideSelectors.selectPackagesIsLoaded)),
      switchMap(([action, loaded]) => (loaded ? [] : this.loadPackages(action.areaId))),
    ),
  );

  businessSiteIdChanged$ = createEffect(() =>
    this.store.select(BookRideSelectors.selectAreaId).pipe(
      filter(id => !!id),
      withLatestFrom(
        this.store.select(BookRideSelectors.selectBusinessId),
        this.store.select(BookRideSelectors.selectBusinessSiteId),
      ),
      switchMap(([areaId, businessId, businessSiteId]) =>
        this.firestoreService.watchUpcomingRides(businessId, businessSiteId).pipe(
          whenPageVisible(),
          takeUntil(this.actions$.pipe(ofType(BookRideActions.createFormDestroyed))),
          switchMap(rides => {
            const ridesWithJobs = rides.filter(ride => !!ride.job);
            if (ridesWithJobs.length === 0) {
              return [rides];
            }
            const vehicleIds = [...new Set(ridesWithJobs.map(ride => ride.job.vehicle_id).filter(id => !!id))];
            return this.ridesFirestoreService.listVehicles(vehicleIds).pipe(
              take(1),
              map(vehicles => {
                rides.forEach(ride => {
                  if (ride.job?.vehicle_id) {
                    ride.vehicle = vehicles.find(vehicle => vehicle.id === ride.job.vehicle_id);
                  }
                });
                return rides;
              }),
            );
          }),
          map(rides => BookRideActions.upcomingRidesLoaded({ rides: rides })),
        ),
      ),
      catchError(error => {
        this.notification.error(error);
        return [];
      }),
    ),
  );

  rideSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.rideSelected, BookRideActions.bookingCancelled),
      switchMap(action =>
        this.ridesFirestoreService.watchBookingAggregate(action.id).pipe(
          whenPageVisible(),
          takeUntil(this.actions$.pipe(ofType(BookRideActions.rideDeselected))),
          filter(aggregate => !!aggregate),
          switchMap(aggregate =>
            combineLatest([
              this.getQuote(aggregate),
              this.getPackage(aggregate),
              this.getDriver(aggregate),
              this.getVehicle(aggregate),
              this.getVehicleType(aggregate),
            ]).pipe(
              whenPageVisible(),
              takeUntil(this.actions$.pipe(ofType(BookRideActions.rideDeselected))),
              switchMap(([quote, productPackage, driver, vehicle, vehicleType]) => {
                const newAggregate = {
                  ...aggregate,
                  quote,
                  productPackage,
                  driver,
                  vehicle,
                  vehicleType,
                };
                return [newAggregate];
              }),
            ),
          ),
        ),
      ),
      switchMap(aggregate => [BookRideActions.rideDetailsChanged({ aggregate })]),
    ),
  );

  sendTrackingLinkClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.sendTrackingLinkClicked),
      withLatestFrom(this.store.select(BookRideSelectors.selectSelectedRideId)),
      switchMap(([action, selectedRideId]) => {
        return this.api.sendTrackingSms(selectedRideId, action.phoneNumber).pipe(
          tap(() => this.notification.success('Tracking link sent')),
          map(_ => BookRideActions.trackingLinkSent()),
          catchError(error => {
            this.notification.error(error);
            return [BookRideActions.sendTrackingLinkFailed()];
          }),
        );
      }),
    ),
  );

  callDriverClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.callDriverClicked),
      withLatestFrom(
        this.store.select(BookRideSelectors.selectSelectedRideId),
        this.store.select(BookRideSelectors.selectBusinessSiteId),
      ),
      switchMap(([action, selectedRideId, businessSiteId]) => {
        return this.api
          .callDriver({
            agentId: businessSiteId,
            bookingId: selectedRideId,
            callerPhoneNumber: action.phoneNumber,
            idempotencyKey: uuid(),
          })
          .pipe(
            tap(() =>
              this.notification.success('We are attempting to connect you to the driver. Please wait for the call'),
            ),
            map(_ => BookRideActions.driverCalled()),
            catchError(error => {
              this.notification.error(error);
              return [BookRideActions.driverCallFailed()];
            }),
          );
      }),
    ),
  );

  packagesLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.packagesLoaded),
      withLatestFrom(this.store.select(BookRideSelectors.selectPackages)),
      switchMap(([action, selectedPackage]) =>
        merge(
          this.loadVehicleTypes(selectedPackage.map(p => p.vehicle_type_id)),
          this.loadFeatures(selectedPackage.map(p => p.feature_ids).reduce((acc, val) => acc.concat(val), [])),
          this.selectPackage(),
        ),
      ),
    ),
  );

  private selectPackage() {
    return this.store.select(BookRideSelectors.selectPackagesVM).pipe(
      filter(packages => packages.length > 0),
      take(1),
      map(packages =>
        BookRideActions.packageIdChanged({
          packageId: packages[0].id,
          vehicleTypeId: packages[0].vehicleTypeId,
          featureIds: packages[0].featureIds,
        }),
      ),
    );
  }

  bookingCancelClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.bookingCancelClicked),
      withLatestFrom(
        this.store.select(AuthSelectors.selectFcUserId),
        this.store.select(BookRideSelectors.selectSelectedRideId),
      ),
      switchMap(([action, userId, selectedRideId]) =>
        this.api
          .cancelBooking({
            id: selectedRideId,
            userId,
            idempotenceKey: uuid(),
          })
          .pipe(
            switchMap(_ =>
              this.firestoreService.watchBookingAggregate(selectedRideId).pipe(
                filter(booking => {
                  const rideStatus = getRideStatus(booking);
                  return rideStatus === RideStatus.CANCELLED;
                }),
                take(1),
                tap(() => this.notification.success('Booking cancelled')),
                switchMap(_ => [BookRideActions.bookingCancelled({ id: selectedRideId })]),
              ),
            ),
            catchError(error => {
              this.notification.error(error);
              return [BookRideActions.bookingCancelFailed()];
            }),
          ),
      ),
    ),
  );

  private loadPackages(areaId: string) {
    return this.firestoreService.watchAreaPackage(areaId).pipe(
      whenPageVisible(),
      takeUntil(this.actions$.pipe(ofType(BookRideActions.createFormDestroyed))),
      filter(areaPackage => !!areaPackage),
      switchMap(areaPackage =>
        this.firestoreService.watchPackages(areaPackage?.package_ids).pipe(
          whenPageVisible(),
          takeUntil(this.actions$.pipe(ofType(BookRideActions.createFormDestroyed))),
          map(packages =>
            BookRideActions.packagesLoaded({ packages: [...packages.values()].filter(value => value !== null) }),
          ),
        ),
      ),
    );
  }

  private loadAllPackages() {
    return this.firestoreService.getAllPackages().pipe(
      take(1),
      map(packages => BookRideActions.allPackagesLoaded({ packages })),
    );
  }

  private loadAllVehicleTypes() {
    return this.store.select(BookRideSelectors.selectAllVehicleTypes).pipe(
      take(1),
      switchMap(vehicleTypes => {
        if (vehicleTypes.length === 0) {
          return this.firestoreService.getAllVehicleTypes().pipe(
            take(1),
            switchMap(vehicleTypes => [BookRideActions.allVehicleTypesLoaded({ vehicleTypes })]),
          );
        } else {
          return [BookRideActions.allVehicleTypesLoaded({ vehicleTypes })];
        }
      }),
    );
  }

  private loadVehicleTypes(ids: string[]) {
    return this.firestoreService.watchVehicleTypes(ids).pipe(
      whenPageVisible(),
      takeUntil(this.actions$.pipe(ofType(BookRideActions.createFormDestroyed))),
      map(vehicleTypes =>
        BookRideActions.vehicleTypesLoaded({
          vehicleTypes: [...vehicleTypes.values()].filter(value => value !== null),
        }),
      ),
    );
  }

  private loadFeatures(ids: string[]) {
    return this.firestoreService.watchFeatures(ids).pipe(
      whenPageVisible(),
      takeUntil(this.actions$.pipe(ofType(BookRideActions.createFormDestroyed))),
      map(features =>
        BookRideActions.featuresLoaded({
          features: [...features.values()].filter(value => value !== null),
        }),
      ),
    );
  }

  quoteIdChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.quoteIdChanged),
      switchMap(action =>
        this.firestoreService.watchQuote(action.quoteId).pipe(
          whenPageVisible(),
          takeUntil(this.actions$.pipe(ofType(BookRideActions.createFormDestroyed))),
          filter(quote => !!quote),
          take(1),
          map(quote => BookRideActions.quoteChanged({ quote })),
        ),
      ),
      catchError(error => {
        this.notification.error(error);
        return [];
      }),
    ),
  );

  packageIdChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.packageIdChanged, BookRideActions.pickupLocationChanged),
      debounceTime(500),
      withLatestFrom(
        this.store.select(BookRideSelectors.selectPickupLocation),
        this.store.select(BookRideSelectors.selectSelectedPackage),
        this.store.select(AuthSelectors.selectShowNearbyDriversLimit),
        this.store.select(AuthSelectors.selectNearbyDriversFetchInterval),
      ),
      filter(([_, pickupLocation, selectedPackage]) => !!pickupLocation && !!selectedPackage),
      switchMap(([_, pickupLocation, selectedPackage, nearbyDriversLimit, fetchInterval]) => {
        return interval(fetchInterval).pipe(
          whenPageVisible(),
          takeUntil(this.actions$.pipe(ofType(BookRideActions.createFormDestroyed))),
          startWith(0),
          switchMap(() => {
            const nearbyDriversRequestPayload: LookupNearbyDriversRequest = {
              location: {
                lat: pickupLocation.lat,
                lng: pickupLocation.lng,
              },
              vehicleTypeId: selectedPackage.vehicle_type_id,
              featureIds: selectedPackage.feature_ids,
              idempotencyKey: uuid(),
              nearbyDriversLimit,
            };
            return this.api.lookupNearbyDrivers(nearbyDriversRequestPayload).pipe(
              switchMap(response => {
                const { eta } = response;
                const etaInMinutes = eta ? Math.floor(+eta / 60).toString() : null;
                return [
                  BookRideActions.nearbyDriversChanged({ nearbyDrivers: response.nearbyDrivers, eta: etaInMinutes }),
                ];
              }),
            );
          }),
        );
      }),
    ),
  );

  quoteRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BookRideActions.packageIdChanged,
        BookRideActions.pickupLocationChanged,
        BookRideActions.dropoffLocationChanged,
        BookRideActions.pickupAtDateTimeChanged,
        BookRideActions.toggleRequestNowSwitched,
      ),
      withLatestFrom(
        this.store.select(BookRideSelectors.selectBusinessId),
        this.store.select(BookRideSelectors.selectAreaId),
        this.store.select(BookRideSelectors.selectSelectedPackage),
        this.store.select(BookRideSelectors.selectPickupLocation),
        this.store.select(BookRideSelectors.selectDropoffLocation),
        this.store.select(BookRideSelectors.selectLocalPickupAtDateTime),
        this.store.select(BookRideSelectors.selectPaymentMethodType),
        this.store.select(BookRideSelectors.selectIsPrebooking),
      ),
      filter(
        ([
          action,
          businessId,
          areaId,
          selectedPackage,
          pickupLocation,
          dropoffLocation,
          localPickupAt,
          paymentMethodType,
          prebooking,
        ]) =>
          !!selectedPackage &&
          !!pickupLocation &&
          !!dropoffLocation &&
          !!businessId &&
          !!areaId &&
          (prebooking === false || !!localPickupAt),
      ),
      tap(() => this.store.dispatch(BookRideActions.quoteRequested())),
      mergeMap(
        ([
          action,
          businessId,
          areaId,
          selectedPackage,
          pickupLocation,
          dropoffLocation,
          localPickupAt,
          paymentMethodType,
        ]) =>
          this.api
            .quote({
              businessId: businessId,
              areaId: areaId,
              dropoff: {
                lat: dropoffLocation.lat,
                lng: dropoffLocation.lng,
                formattedAddress: dropoffLocation.formattedAddress,
              },
              pickup: {
                lat: pickupLocation.lat,
                lng: pickupLocation.lng,
                formattedAddress: pickupLocation.formattedAddress,
              },
              vehicleTypeId: selectedPackage.vehicle_type_id,
              featureIds: selectedPackage.feature_ids,
              paymentMethod: {
                type: mapPaymentMethodTypeVMToPaymentMethodType(paymentMethodType),
              },
              localPickupAt: localPickupAt,
            })
            .pipe(
              // Waiting for quote data sync into firestore
              switchMap(response =>
                this.firestoreService.watchQuote(response.quote.id).pipe(
                  filter(quote => !!quote),
                  take(1),
                ),
              ),
              map(quote => BookRideActions.quoteChanged({ quote: quote })),
            ),
      ),
    ),
  );

  rideRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.rideRequested),
      withLatestFrom(
        this.store.select(BookRideSelectors.selectQuote),
        this.store.select(BookRideSelectors.selectPackageId),
        this.store.select(BookRideSelectors.selectVehicleTypeId),
        this.store.select(BookRideSelectors.selectFeatureIds),
        this.store.select(BookRideSelectors.selectBusinessId),
        this.store.select(BookRideSelectors.selectBusinessSiteId),
        this.store.select(BookRideSelectors.selectPaymentMethodType),
      ),
      switchMap(
        ([action, quote, packageId, vehicleTypeId, featuresIds, businessId, businessSiteId, paymentMethodType]) =>
          this.api
            .createBooking({
              quoteId: quote?.id,
              paymentMethod: {
                type: mapPaymentMethodTypeVMToPaymentMethodType(paymentMethodType),
              },
              packageId: packageId,
              vehicleTypeId: vehicleTypeId,
              featureIds: featuresIds,
              pickup: action.request.pickupLocation,
              dropoff: action.request.dropoffLocation,
              note: action.request.noteToDriver,
              phoneNumber: action.request.contactNumber,
              businessId: businessId,
              businessSiteId: businessSiteId,
              localPickupAt: action.request.pickupAt,
            })
            .pipe(
              // Waiting for quote data sync into firestore
              switchMap(response =>
                this.firestoreService.watchBookingAggregate(response.booking.id).pipe(
                  filter(booking => !!booking),
                  take(1),
                ),
              ),
              map(() => BookRideActions.rideRequestSucceeded()),
              tap(() => this.notification.success('Ride requested created')),
              catchError(error => {
                this.notification.error(error);
                return of(BookRideActions.rideRequestFailed());
              }),
            ),
      ),
    ),
  );

  nearbyDriversChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookRideActions.nearbyDriversChanged),
      withLatestFrom(this.store.select(AuthSelectors.isShowNearbyDriversEnabled)),
      filter(([_action, isShowNearbyDriversEnabled]) => isShowNearbyDriversEnabled),
      withLatestFrom(this.store.select(BookRideSelectors.selectUpcomingRides)),
      switchMap(([[{ nearbyDrivers }], upcomingRides]) => {
        const upcomingRidesDrivers = upcomingRides.map(ride => ride.job?.driver_id);
        const nearbyDriversIds = nearbyDrivers.map(driver => driver.driverId);
        const driverIds = [...new Set([...upcomingRidesDrivers, ...nearbyDriversIds])];
        if (driverIds?.length > 0) {
          return combineLatest([
            this.firestoreService.getDriversTelemetries(driverIds),
            this.firestoreService.getDriversStates(driverIds),
          ]).pipe(
            switchMap(([telemetries, state]) => {
              const driversTelemetries = driverIds.map(driverId => ({
                ...telemetries.find(t => t?.driver_id === driverId),
                driverStatus: state?.find(s => s?.driver_id === driverId).status,
              }));
              return [BookRideActions.nearbyDriversTelemetriesChanged({ nearbyDrivers: driversTelemetries })];
            }),
          );
        } else {
          return [BookRideActions.nearbyDriversTelemetriesChanged({ nearbyDrivers: [] })];
        }
      }),
    ),
  );

  private getPackage(aggregate: BookingAggregate) {
    if (!aggregate.booking.package_id) {
      return of(null);
    }
    return this.ridesFirestoreService.watchPackage(aggregate.booking.package_id).pipe(
      takeUntil(this.actions$.pipe(ofType(BookRideActions.rideDeselected))),
      map(productPackage => productPackage),
    );
  }

  private getQuote(aggregate: BookingAggregate) {
    if (!aggregate.booking.quote_id) {
      return of(null);
    }
    return this.ridesFirestoreService.watchQuote(aggregate.booking.quote_id).pipe(
      takeUntil(this.actions$.pipe(ofType(BookRideActions.rideDeselected))),
      map(quote => quote),
    );
  }

  private getDriver(aggregate: BookingAggregate) {
    if (aggregate.trip?.driver) {
      return of(aggregate.trip?.driver);
    }

    if (aggregate.job?.driver_id) {
      const rideStatus = getRideStatus(aggregate);
      if (rideStatus === RideStatus.IN_PROGRESS || rideStatus === RideStatus.ACCEPTED) {
        return combineLatest([
          this.firestoreService.watchDriver(aggregate.job.driver_id),
          this.firestoreService.watchDriverTelemetry(aggregate.job.driver_id),
        ]).pipe(
          whenPageVisible(),
          takeUntil(this.actions$.pipe(ofType(BookRideActions.rideDeselected))),
          map(([driver, driverTelemetry]) => ({ ...driver, telemetry: driverTelemetry })),
        );
      }

      return this.firestoreService.watchDriver(aggregate.job.driver_id).pipe(
        takeUntil(this.actions$.pipe(ofType(BookRideActions.rideDeselected))),
        map(driver => ({ ...driver, telemetry: null })),
      );
    }

    return of(null);
  }

  private getVehicle(aggregate: BookingAggregate) {
    if (!aggregate.job?.vehicle_id) {
      return of(null);
    }
    return this.firestoreService.watchVehicle(aggregate.job.vehicle_id).pipe(
      whenPageVisible(),
      takeUntil(this.actions$.pipe(ofType(BookRideActions.rideDeselected))),
      map(vehicle => vehicle),
    );
  }

  private getVehicleType(aggregate: BookingAggregate) {
    if (!aggregate.booking.vehicle_type_id) {
      return of(null);
    }
    return this.firestoreService.watchVehicleType(aggregate.booking.vehicle_type_id).pipe(
      takeUntil(this.actions$.pipe(ofType(BookRideActions.rideDeselected))),
      map(vehicleType => vehicleType),
    );
  }
}
