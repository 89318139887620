import { Component, OnInit } from '@angular/core';
import { ThemeService } from './core/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isDarkTheme: boolean | null = null;
  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.watchTheme();
  }

  private watchTheme() {
    this.themeService.isDarkTheme$.pipe().subscribe(isDarkTheme => {
      this.isDarkTheme = isDarkTheme;
    });
  }
}
