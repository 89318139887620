/* eslint-disable  @typescript-eslint/no-empty-interface */
import { createReducer } from '@ngrx/store';

export const analyticsFeatureKey = 'analytics';

export interface State {
}

export const initialState: State = {
};

export const reducer = createReducer(
    initialState,
);
