import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthStoreModule } from './auth/store/auth-store.module';
import { HomeStoreModule } from './home/store/home-store.module';
import { IntercomStoreModule } from './shared/store/modules/intercom/intercom-store.module';
import { RouterStoreModule } from './shared/store/modules/router/router-store.module';
import { AnalyticsStoreModule } from './shared/store/modules/analytics/analytics-store.module';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateImmutability: true,
        strictStateSerializability: true
      }
    }),
    EffectsModule.forRoot([]),
    AuthStoreModule,
    RouterStoreModule,
    IntercomStoreModule,
    HomeStoreModule,
    AnalyticsStoreModule
  ]
})
export class AppStoreModule { }
