<h2 mat-dialog-title>Confirm trip quote change</h2>
<mat-dialog-content>
  <div class="vig-body-1">Changing the pre-booking time will update the trip quote:</div>
    <div class="vig-subtitle-1 quote-change">
        <span class="regular">From:</span>
        <span>{{ data.oldQuote }}</span>
        <mat-icon class="material-symbols-outlined arrow-forward">arrow_forward</mat-icon>
         <span class="regular">To:</span>
         <span>{{ data.newQuote }}</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="actions-container">
  <button mat-raised-button [disabled]="isLoading" color="accent" (click)="confirm()">Confirm</button>
  <button mat-stroked-button [disabled]="isLoading" (click)="cancel()">Cancel</button>
  @if(isLoading) {
    <mat-spinner color="accent" class="busy-spinner" [diameter]="24"></mat-spinner>
  }
</mat-dialog-actions>