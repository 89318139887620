import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Actions } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ActionTrackerService implements OnDestroy {
  private actionHistory: unknown[] = [];
  private destroy = new Subject<void>();

  constructor(private actions$: Actions) {
    this.trackActions();
  }

  private trackActions(): void {
    this.actions$.pipe(takeUntil(this.destroy)).subscribe(action => {
      this.actionHistory.push({
        type: action.type,
        payload: action.type,
        timestamp: new Date().toISOString(),
      });

      if (this.actionHistory.length > 10) {
        this.actionHistory.shift();
      }
    });
  }

  getActionHistory(): unknown[] {
    return this.actionHistory;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
