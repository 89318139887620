import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { RouterEffects } from './router.effects';

@NgModule({
    imports: [
        StoreModule.forFeature('router', routerReducer),
        EffectsModule.forFeature([RouterEffects]),
        StoreRouterConnectingModule.forRoot(),
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class RouterStoreModule {}