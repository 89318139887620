import { inject, Injectable } from '@angular/core';
import 'firebase/compat/firestore';
import {
  AgentAggregate,
  AppConfig,
  Area,
  AreaPackage,
  BookingAggregate,
  BusinessAggregate,
  BusinessNote,
  Driver,
  DriverState,
  DriverTelemetry,
  ExternalAccountManagerAggregate,
  Feature,
  Package,
  PlatformSettings,
  Quote,
  Vehicle,
  VehicleType,
} from '../models/firestore.model';
import { where, query, Firestore, collection, or, orderBy, onSnapshot } from '@angular/fire/firestore';
import { FirestoreHelperService } from './firestore-helper.service';
import { and, QueryCompositeFilterConstraint, QueryNonFilterConstraint } from 'firebase/firestore';
import { from, interval, map, mergeMap, Observable, startWith, switchMap, take, toArray } from 'rxjs';

@Injectable()
export class FirestoreService {
  private firestore = inject(Firestore);
  private firestoreHelper = inject(FirestoreHelperService);

  watchAppConfig(): Observable<AppConfig> {
    return new Observable(observer => {
      const appConfigCollectionRef = collection(this.firestore, 'app_config');
      const q = query(appConfigCollectionRef);

      const unsubscribe = onSnapshot(
        q,
        snapshot => {
          const appConfigArray = snapshot.docs
            .filter(docSnap => docSnap.exists)
            .reduce((prev, curr) => ({ ...prev, [curr.id]: curr.data() }), {});
          observer.next(appConfigArray);
        },
        error => {
          observer.error(error);
        },
      );

      return () => unsubscribe();
    });
  }

  getAllPackages() {
    return this.firestoreHelper.colData<Package>(collection(this.firestore, 'package'));
  }

  getAllVehicleTypes() {
    return this.firestoreHelper.colData<VehicleType>(collection(this.firestore, 'vehicle_type'));
  }

  watchBusinessAggregate(businessId: string) {
    return this.firestoreHelper.docData<BusinessAggregate>(
      `role/external_account_manager/business_aggregate/${businessId}`,
    );
  }

  watchExternalAccountManagerAggregate(id: string) {
    return this.firestoreHelper.docData<ExternalAccountManagerAggregate>(
      `role/external_account_manager/external_account_manager_aggregate/${id}`,
    );
  }

  watchAgentAggregate(id: string) {
    return this.firestoreHelper.docData<AgentAggregate>(`agent_aggregate/${id}`);
  }

  watchAreaPackage(areaId: string) {
    return this.firestoreHelper.docData<AreaPackage>(`area/${areaId}/package/current`);
  }

  watchPlatformSettings() {
    return this.firestoreHelper.docData<PlatformSettings>('setting/current');
  }

  watchPackages(ids: string[]) {
    return this.firestoreHelper.getDocumentsByIds<Package>('package', ids);
  }

  watchVehicleTypes(ids: string[]) {
    return this.firestoreHelper.getDocumentsByIds<VehicleType>('vehicle_type', ids);
  }

  watchFeatures(ids: string[]) {
    return this.firestoreHelper.getDocumentsByIds<Feature>('feature', ids);
  }

  watchQuote(id: string) {
    return this.firestoreHelper.docData<Quote>(`quote/${id}`);
  }

  watchBookingAggregate(id: string) {
    return this.firestoreHelper.docData<BookingAggregate>(`booking_aggregate/${id}`);
  }

  watchDriver(id: string) {
    return this.firestoreHelper.docData<Driver>(`driver/${id}`);
  }

  getDriversTelemetries(ids: string[]) {
    return from(ids).pipe(
      mergeMap(driverId =>
        this.firestoreHelper.docData<DriverTelemetry>(`driver/${driverId}/telemetry/current`).pipe(take(1)),
      ),
      toArray(),
    );
  }

  watchDriverTelemetry(id: string): Observable<DriverTelemetry> {
    return interval(15000).pipe(
      startWith(0),
      switchMap(() => this.firestoreHelper.docData<DriverTelemetry>(`driver/${id}/telemetry/current`).pipe(take(1))),
    );
  }

  getDriversStates(ids: string[]) {
    return from(ids).pipe(
      mergeMap(driverId =>
        this.firestoreHelper
          .docData<DriverState>(`driver/${driverId}/state/current`)
          .pipe(take(1))
          .pipe(map(state => ({ ...state, driver_id: driverId }))),
      ),
      toArray(),
    );
  }

  watchVehicle(id: string) {
    return this.firestoreHelper.docData<Vehicle>(`vehicle/${id}`);
  }

  watchVehicleType(id: string) {
    return this.firestoreHelper.docData<VehicleType>(`vehicle_type/${id}`);
  }

  watchAreas(): Observable<Area[]> {
    const colRef = collection(this.firestore, `area`);
    return this.firestoreHelper.colData<Area>(colRef);
  }

  watchBusinessNote(businessId: string): Observable<BusinessNote> {
    return this.firestoreHelper.docData<BusinessNote>(`note/${businessId}`);
  }

  watchUpcomingRides(businessId: string, businessSiteId: string): Observable<BookingAggregate[]> {
    const now = new Date();
    const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000);

    const collectionPath = `booking_aggregate`;
    const colRef = collection(this.firestore, collectionPath);

    const constraints: QueryCompositeFilterConstraint = and(
      and(where('booking.business_id', '==', businessId)),
      and(where('booking.business_site_id', '==', businessSiteId)),
      or(
        where('booking.created_at', '>=', oneHourAgo),
        and(where('booking.pickup_at', '>=', oneHourAgo), where('booking.is_prebooking', '==', true)),
      ),
    );

    const nonQueryConstraints: QueryNonFilterConstraint[] = [orderBy('booking.created_at', 'desc')];
    const combinedQuery = query(colRef, constraints, ...nonQueryConstraints);

    return new Observable<BookingAggregate[]>(observer => {
      return onSnapshot(
        combinedQuery,
        querySnapshot => {
          let docs = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...(this.firestoreHelper.toSerializable(doc.data()) as BookingAggregate),
          }));
          const thirtySecondsAgo = Date.now() - 30000;
          docs = docs.filter(
            doc =>
              doc.job?.completed_at == null &&
              doc.job?.ended_at == null &&
              doc.trip?.ended_at == null &&
              (doc.job?.in_progress_at == null ||
                (doc.job?.in_progress_at && doc.job.in_progress_at > thirtySecondsAgo)) &&
              doc.booking.expired_at == null &&
              (doc.booking.cancelled_at == null ||
                (doc.booking.cancelled_at && doc.booking.cancelled_at > thirtySecondsAgo)) &&
              (doc.booking.pickup_at == null || doc.dispatch_trigger?.fired_at != null),
          );
          observer.next(docs);
        },
        error => {
          observer.error(error);
        },
      );
    });
  }
}
