import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AngularFirebaseService } from '../shared/services/angular-firebase.service';
import { B2BWebApiService } from '../shared/services/b2b-web-api.service';
import { NotificationService } from '../shared/services/notification.service';
import { DateHelperService } from '../shared/services/date-helper.service';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  providers: [
    AngularFirebaseService,
    B2BWebApiService,
    NotificationService,
    DateHelperService
  ]
})
export class CoreModule { }
