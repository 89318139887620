import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs';
import { DashboardActions } from 'src/app/home/dashboard/store';
import { RequestRideActions } from 'src/app/home/request-ride/store';
import { AngularFirebaseService } from 'src/app/shared/services/angular-firebase.service';
import { RouterSelectors } from '../router';
import { RouteData } from 'src/app/shared/models/route-data.model';

@Injectable()
export class AnalyticsEffects {
    constructor(
        private actions$: Actions,
        private firebaseService: AngularFirebaseService,
        private store: Store
    ) { }

    watchRouteScreenEvents$ = createEffect(() =>
        this.store.select(RouterSelectors.selectRouteData).pipe(
            filter(data => !!data),
            map(data => data as RouteData),
            tap(data => this.firebaseService.logScreenEvent(data.analyticsScreenName))
        ), { dispatch: false }
    );

    requestDashboardAccessClicked$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.requestAccessClicked),
            tap(_ => this.firebaseService.logEvent("Dashboard Access Requested"))
        ), { dispatch: false }
    );

    requestRequestRideAccessClicked$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RequestRideActions.requestAccessClicked),
            tap(_ => this.firebaseService.logEvent("Booker Access Requested"))
        ), { dispatch: false }
    );
}
