import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { AuthActions, AuthSelectors } from 'src/app/auth/store';
import { B2BWebApiService } from 'src/app/shared/services/b2b-web-api.service';
import { IntercomService } from 'src/app/shared/services/intercom.service';
import { uuid } from 'src/app/shared/utils/uuid';
@Injectable()
export class IntercomEffects {
  constructor(
    private actions$: Actions,
    private intercom: IntercomService,
    private store: Store,
    private webApi: B2BWebApiService,
  ) {}

  userLoggedIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authStateChanged),
        withLatestFrom(this.store.select(AuthSelectors.selectUid), this.store.select(AuthSelectors.selectIsHandset)),
        filter(([, userId, isHandset]) => !!userId),
        switchMap(([, userId, isHandset]) =>
          this.webApi.createUserIntercomHash({ idempotencyKey: uuid() }).pipe(
            filter(userHash => !!userHash),
            tap(response => this.intercom.boot(userId, response.userHash, isHandset)),
            catchError(_ => {
              return of(null);
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  userLoggedOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authStateChanged),
        withLatestFrom(this.store.select(AuthSelectors.selectUid)),
        filter(([, userId]) => !userId),
        tap(_ => this.intercom.shutdown()),
      ),
    { dispatch: false },
  );
}
