import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Observable, catchError, filter, map, merge, of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import * as UsersActions from './users.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Action, Store } from '@ngrx/store';
import { HomeSelectors } from '../../store';
import { FirestoreService } from 'src/app/shared/services/firestore-service';
import { B2BWebApiService } from 'src/app/shared/services/b2b-web-api.service';
import { UsersSelectors } from '.';
import { uuid } from 'src/app/shared/utils/uuid';
import { IntercomService } from 'src/app/shared/services/intercom.service';
import { UsersFirestoreService } from '../services/users-firestore.service';
import { MetaOp, RiderAggregate, RiderInvitationAggregate } from 'src/app/shared/models/firestore.model';
import { UserTableRow, UsersTableVM } from './users.reducer';
import { SidenavService } from 'src/app/shared/services/sidenav.service';

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private notification: NotificationService,
    private sideNavService: SidenavService,
    private firestore: FirestoreService,
    private store: Store,
    private api: B2BWebApiService,
    private intercom: IntercomService,
    private usersFirestoreService: UsersFirestoreService,
  ) {}

  usersComponentInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.UsersComponentInit),
      switchMap(_ =>
        this.store.select(HomeSelectors.selectBusinessId).pipe(
          filter(businessId => !!businessId),
          take(1),
        ),
      ), // wait for business to be loaded
      switchMap(businessId => merge(this.watchDataSourceParameters())),
    ),
  );

  inviteUserClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.inviteUserClicked),
      withLatestFrom(this.store.select(HomeSelectors.selectBusinessId)),
      switchMap(([action, businessId]) =>
        this.api.createRiderInvitation({ idempotencyKey: uuid(), businessId, email: action.email }).pipe(
          switchMap(response =>
            this.usersFirestoreService.watchRiderInvitationAggregate(businessId, response.invitation.id).pipe(
              filter(invitationAggregate => !!invitationAggregate?.invitation),
              take(1),
              tap(_ => {
                this.notification.success('User invited');
                this.sideNavService.closeRightSidenav();
              }),
            ),
          ),
          catchError(error => {
            const errorCode = error?.error?.message?.split('|')[0];
            if (errorCode === 'CREATE_INVITATION_NOT_ALLOWED__ALREADY_EXISTS') {
              this.notification.error('User already invited');
            } else {
              this.notification.error(error);
            }
            return of(null);
          }),
        ),
      ),
      switchMap(response =>
        response
          ? [UsersActions.inviteUserSuccess(), UsersActions.inviteUserFinished()]
          : [UsersActions.inviteUserFinished()],
      ),
    ),
  );

  resendInviteClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.resendInvitationClicked),
      switchMap(action =>
        this.api.resendRiderInvitation({ idempotencyKey: uuid(), id: action.invitationId }).pipe(
          tap(_ => this.notification.success('Invitation sent')),
          catchError(error => {
            this.notification.error(error);
            return of(null);
          }),
        ),
      ),
      switchMap(_ => [UsersActions.resendInvitationFinished()]),
    ),
  );

  removeUserClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.removeUserClicked),
      switchMap(action =>
        this.dialog
          .open(ConfirmationDialogComponent, {
            data: {
              title: 'Remove user?',
              message: action.email,
              submitButtonText: 'Remove',
              cancelButtonText: 'Cancel',
              type: 'warn',
            } as ConfirmationDialogData,
            autoFocus: false,
          })
          .afterClosed()
          .pipe(take(1))
          .pipe(
            switchMap(confirmed =>
              confirmed
                ? action.businessProfileId
                  ? [
                      UsersActions.removeRiderBusinessProfileConfirmed({
                        inviationId: action.invitationId,
                        businessProfileId: action.businessProfileId,
                      }),
                    ]
                  : [UsersActions.removeUserInvitationConfirmed({ invitationId: action.invitationId })]
                : [],
            ),
          ),
      ),
    ),
  );

  removeUserInvitationConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.removeUserInvitationConfirmed),
      switchMap(action =>
        this.api.deleteRiderInvitation({ id: action.invitationId }).pipe(
          withLatestFrom(this.store.select(HomeSelectors.selectBusinessId)),
          switchMap(([response, businessId]) =>
            this.usersFirestoreService.watchRiderInvitationAggregate(businessId, action.invitationId).pipe(
              filter(invitationAggregate => invitationAggregate?.invitation?.meta_op == MetaOp.DELETE),
              take(1),
              tap(_ => this.notification.success('User removed')),
            ),
          ),
          catchError(error => {
            this.notification.error(error);
            return of(null);
          }),
        ),
      ),
      switchMap(response =>
        response
          ? [UsersActions.removeUserSuccess(), UsersActions.removeUserFinished()]
          : [UsersActions.removeUserFinished()],
      ),
    ),
  );

  removeRiderBusinessProfileConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.removeRiderBusinessProfileConfirmed),
      switchMap(action =>
        this.api.deleteRiderBusinessProfile({ id: action.businessProfileId }).pipe(
          withLatestFrom(this.store.select(HomeSelectors.selectBusinessId)),
          switchMap(([response, businessId]) =>
            this.usersFirestoreService.watchRiderInvitationAggregate(businessId, action.inviationId).pipe(
              filter(invitationAggregate => invitationAggregate?.business_profile?.meta_op == MetaOp.DELETE),
              take(1),
              tap(_ => this.notification.success('User removed')),
            ),
          ),
          catchError(error => {
            this.notification.error(error);
            return of(null);
          }),
        ),
      ),
      switchMap(response =>
        response
          ? [UsersActions.removeUserSuccess(), UsersActions.removeUserFinished()]
          : [UsersActions.removeUserFinished()],
      ),
    ),
  );

  requestAccessClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UsersActions.requestAccessClicked),
        tap(_ => this.intercom.showNewMessages()),
      ),
    { dispatch: false },
  );

  userInvitedOrRemoved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.inviteUserSuccess, UsersActions.removeUserSuccess),
      switchMap(_ => this.loadRiderInvitationAggregates()),
    ),
  );

  private watchDataSourceParameters(): Observable<Action> {
    return this.store
      .select(UsersSelectors.selectDataSourceParameters)
      .pipe(switchMap(_ => this.loadRiderInvitationAggregates()));
  }

  private loadRiderInvitationAggregates() {
    return this.store.select(HomeSelectors.selectBusinessId).pipe(
      filter(businessId => !!businessId),
      take(1),
      concatLatestFrom(() => this.store.select(UsersSelectors.selectTableVM)),
      withLatestFrom(this.store.select(UsersSelectors.selectDataSourceParameters)),
      switchMap(([[businessId, currentTableVM], dataSourceParameters]) => {
        return this.usersFirestoreService
          .listRiderInvitationAggregates(
            <string>businessId,
            {
              email: dataSourceParameters.text,
            },
            {
              pageSize: dataSourceParameters.pageSize,
              previousPageIndex: dataSourceParameters.previousPageIndex,
              pageIndex: dataSourceParameters.pageIndex,
              firstDocumentId:
                currentTableVM.rows && currentTableVM.rows.length > 0 ? currentTableVM.rows[0].invitationId : null,
              lastDocumentId:
                currentTableVM.rows && currentTableVM.rows.length > 0
                  ? currentTableVM.rows[currentTableVM.rows.length - 1].invitationId
                  : null,
            },
          )
          .pipe(
            switchMap(usersPagedData => {
              const riderIds = usersPagedData.pageRows
                .map(aggregate => aggregate.business_profile?.rider_id)
                .filter(riderId => !!riderId);
              if (riderIds.length === 0) {
                return of({ usersPagedData, riderAggregates: [] });
              }
              return this.usersFirestoreService.listRiders(riderIds).pipe(
                take(1),
                map(riderAggregates => ({
                  usersPagedData,
                  riderAggregates: [...riderAggregates.values()].filter(value => value !== null),
                })),
              );
            }),
          );
      }),
      map(result =>
        UsersActions.dataChanged({
          tableVM: this.buildTableVM(result.usersPagedData.pageRows, result.riderAggregates),
          totalCount: result.usersPagedData.totalCount,
        }),
      ),
    );
  }

  private buildTableVM(
    riderInvitationAggregates: RiderInvitationAggregate[],
    riderAggregates: RiderAggregate[],
  ): UsersTableVM {
    return {
      rows: riderInvitationAggregates.map(riderInvitationAggregates => {
        const rider = riderAggregates.find(
          riderAggregate => riderAggregate.rider.id === riderInvitationAggregates.business_profile?.rider_id,
        )?.rider;
        return <UserTableRow>{
          businessProfileId: riderInvitationAggregates.business_profile?.id,
          invitationId: riderInvitationAggregates.invitation.id,
          email: riderInvitationAggregates.invitation.email,
          name: rider ? rider.first_name + ' ' + rider.last_name : null,
          pendingInvite: !riderInvitationAggregates.invitation.accepted_at,
        };
      }),
    };
  }
}
