import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import { Observable } from 'rxjs';
import { AuthActions } from 'src/app/auth/store';
import { config } from 'src/environments/config';

@Injectable()
export class AngularFirebaseService {

    constructor(private store: Store) { }

    initFirebase() {
        firebase.initializeApp(config.firebase);
        firebase.auth().onAuthStateChanged(user => {
          this.store.dispatch(AuthActions.authStateChanged({ uid: user ? user.uid : null}));
          if (user) {
            user.getIdToken().then(token => {
              this.store.dispatch(AuthActions.tokenChanged({ token }));
            });
          }
        });
    }

    auth() {
        return firebase.auth();
    }

    firestore() {
        return firebase.firestore();
    }

    storage() {
        return firebase.storage();
    }

    userIdTokenResult() {
        return new Observable<string>(subscriber => {
            firebase.auth().currentUser.getIdToken()
                .then(token => {
                    subscriber.next(token);
                    subscriber.complete();
                })
                .catch(error => {
                    subscriber.error(error);
                    subscriber.complete();
                });
        });
    }

    signInWithEmailAndPassword(email: string, password: string) {
        return new Observable<firebase.auth.UserCredential>(subscriber => {
            firebase.auth().signInWithEmailAndPassword(email, password)
                .then(user => {
                    subscriber.next(user);
                    subscriber.complete();
                })
                .catch(error => {
                    subscriber.error(error);
                    subscriber.complete();
                });
        });
    }

    signInWithCustomToken(token: string) {
        return new Observable<firebase.auth.UserCredential>(subscriber => {
            firebase.auth().signInWithCustomToken(token)
                .then(user => {
                    subscriber.next(user);
                    subscriber.complete();
                })
                .catch(error => {
                    subscriber.error(error);
                    subscriber.complete();
                });
        });
    }

    logout() {
        return new Observable<firebase.auth.UserCredential>(subscriber => {
            firebase.auth().signOut()
                .then(_ => {
                    this.store.dispatch(AuthActions.logoutFinished());
                    subscriber.next();
                    subscriber.complete();
                })
                .catch(error => {
                    subscriber.error(error);
                    subscriber.complete();
                });
        });
    }

    logEvent(eventName: string) {
        firebase.analytics().logEvent(eventName);
    }

    logScreenEvent(eventName: string) {
        firebase.analytics().logEvent('screen_view', { 'firebase_screen': eventName, 'firebase_screen_class': null });
    }
}
