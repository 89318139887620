import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IntercomService } from 'src/app/shared/services/intercom.service';
import {  IntercomEffects } from './intercom.effects';
import * as fromAuth from './intercom.reducer';


@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(fromAuth.intercomFeatureKey, fromAuth.reducer),
  EffectsModule.forFeature([IntercomEffects])],
  providers: [IntercomService]
})
export class IntercomStoreModule { }