import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth.effects';
import { FirestoreService } from 'src/app/shared/services/firestore-service';


@NgModule({
  declarations: [],
  imports: [
  StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducer),
  EffectsModule.forFeature([AuthEffects])],
  providers: [
    FirestoreService
  ]
})
export class AuthStoreModule { }
