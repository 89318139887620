import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromDashboard from './store/settings.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SettingsEffects } from './store/settings.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromDashboard.settingsFeatureKey, fromDashboard.reducer),
    EffectsModule.forFeature([SettingsEffects]),
  ],
})
export class SettingsStoreModule {}
