import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatWith, filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AuthActions, AuthSelectors } from 'src/app/auth/store';
import { BusinessAggregate } from 'src/app/shared/models/firestore.model';
import { FirestoreService } from 'src/app/shared/services/firestore-service';
import * as HomeActions from './home.actions';
import { whenPageVisible } from '../../shared/utils/when-page-visible';

@Injectable()
export class HomeEffects {
  private actions$ = inject(Actions);
  private firestore = inject(FirestoreService);
  private store = inject(Store);

  externalAccountManagerBusinessIdChanged$ = createEffect(() =>
    this.store.select(AuthSelectors.selectBusinessId).pipe(
      filter(businessId => !!businessId),
      switchMap(businessId =>
        this.firestore
          .watchBusinessAggregate(businessId)
          .pipe(
            whenPageVisible(),
            takeUntil(this.actions$.pipe(ofType(AuthActions.logoutClicked))),
            takeUntil(this.store.select(AuthSelectors.selectBusinessId).pipe(filter(businessId => !businessId))),
            concatWith(of(<BusinessAggregate>null)),
          ),
      ),
      switchMap(aggregate => [HomeActions.businessAggregateChanged({ aggregate })]),
    ),
  );

  failedPaymentsChanged$ = createEffect(() =>
    this.store.select(AuthSelectors.selectBusinessId).pipe(
      filter(businessId => !!businessId),
      withLatestFrom(this.store.select(AuthSelectors.selectAgentAggregate)),
      switchMap(([businessId, agentAggregate]) =>
        this.firestore.watchFailedPayments(businessId, agentAggregate?.agent?.id).pipe(
          whenPageVisible(),
          takeUntil(this.actions$.pipe(ofType(AuthActions.logoutClicked))),
          takeUntil(this.store.select(AuthSelectors.selectBusinessId).pipe(filter(businessId => !businessId))),

          map(failedPayments => HomeActions.failedPaymentsChanged({ failedPayments })),
        ),
      ),
    ),
  );
}
