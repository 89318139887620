import { Component, DestroyRef, Inject, OnInit, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { RidesActions, RidesSelectors } from "src/app/home/rides/store";

interface ConfirmQuoteChangeDialogData {
    oldQuote: string;
    newQuote: string;
}

@Component({
    selector: 'app-confirm-quote-change-dialog',
    templateUrl: 'confirm-quote-change-dialog.component.html',
    styleUrls: ['confirm-quote-change-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule, MatIconModule, MatProgressSpinnerModule],
  })
  export class ConfirmQuoteChangeDialogComponent implements OnInit {
    destroyRef = inject(DestroyRef);
    readonly data = inject<ConfirmQuoteChangeDialogData>(MAT_DIALOG_DATA);
    isLoading = false;
    modalAction$ = new Subject<boolean>;

    constructor(
      private store: Store,
      public dialogRef: MatDialogRef<ConfirmQuoteChangeDialogComponent>,
      private actions$: Actions) {}

    ngOnInit() {
        this.store.select(RidesSelectors.selectIsConfirmWithNewQuoteInProgress).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isLoading => this.isLoading = isLoading);
    }

    confirm() {
        this.store.dispatch(RidesActions.updateWithNewQuoteConfirmClicked());
        this.modalAction$.next(true);
    }

    cancel() {
        this.modalAction$.next(false);
    }
  }