import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { IntercomService } from 'src/app/shared/services/intercom.service';
import { DashboardActions } from '.';


@Injectable()
export class DashboardEffects {
  constructor(private actions$: Actions, private intercom: IntercomService) { }

  requestAccessClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.requestAccessClicked),
      tap(_ => this.intercom.showNewMessages())
    ), { dispatch: false }
  );
}
