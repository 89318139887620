/* eslint-disable  @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { config } from 'src/environments/config';
import { AngularFirebaseService } from '../services/angular-firebase.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private firebase: AngularFirebaseService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(req.url.startsWith(config.b2bWebApi) && !req.url.includes('signin') && !req.url.includes('signup') && !req.url.includes('sendpasswordresetemail')) {
            return this.firebase.userIdTokenResult().pipe(
                catchError(_ => {
                    return of(null);
                }),
                mergeMap(token => {
                    if (token) {
                        req = req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token}`,
                            }
                        });
                    }
                    return next.handle(req);
                })
            );
        } else {
            return next.handle(req);
        }
    }
}
